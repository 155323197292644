<template>
  <v-row>
    <v-col md="9">
      <v-row dense no-gutters>
        <v-col>
          <h2>
            <small class="font-weight-light">Sessione</small>
            <br />
            {{ session.project_key }}-{{ session.session_code }}-{{
              session.client_code
            }}
          </h2> </v-col
        ><v-col>
          <h2>
            <small class="font-weight-light">Dispositivo</small>
            <br />
            {{ session.device_brand }} {{ session.device_model }}
          </h2>
        </v-col>
        <v-col>
          <h2>
            <small class="font-weight-light">OS</small>
            <br />
            {{ session.device_os }} {{ session.device_os_version }}
          </h2>
        </v-col>
        <v-col>
          <h2>
            <small class="font-weight-light">Durata</small>
            <br />
            <SessionTimer :count="count" />
          </h2>
        </v-col>
      </v-row>
    </v-col>
    <v-col md="3">
      <p>
        <v-btn color="green" block @click="onStartRemoteAccess">
          <v-icon left>mdi-remote-desktop</v-icon>
          Avvia Accesso Remoto</v-btn
        >
      </p>

      <v-btn
        v-if="capabilities.video"
        :disabled="video"
        color="blue"
        block
        @click="onSwitchToVideo"
      >
        <v-icon left>mdi-video-account</v-icon>
        Passa a Video Chat
      </v-btn>
    </v-col>

    <v-col cols="12">
      <SessionVideo v-if="video" @terminated="video = false" />
      <ClosingForm v-else class="col-md-8" />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SessionVideo from '@components/sessions/SessionVideo.vue'
import ClosingForm from '@components/sessions/ClosingForm.vue'
import SessionTimer from '@components/sessions/inc/SessionTimer.vue'

export default {
  name: 'SessionRemoteDesktop',
  components: { SessionTimer, ClosingForm, SessionVideo },
  computed: {
    ...mapState('sessions', {
      session: 'current',
    }),
    ...mapGetters('adminProjects', {
      settings: 'getSettings',
      capabilities: 'getCapabilities',
    }),
  },
  data: () => ({
    video: false,
    count: false,
  }),
  mounted() {
    this.setPending(false)
  },
  methods: {
    ...mapActions('sessions', ['notifyVideo']),
    ...mapMutations('sessions', {
      setPending: 'SET_PENDING',
    }),
    async onSwitchToVideo() {
      if (!this.video) {
        const res = await this.$dialog.confirm({
          text: 'Avviare la sessione video?',
          title: 'Attenzione',
        })

        if (res) {
          await this.notifyVideo()
          this.video = true
        }
      } else {
        const res = await this.$dialog.confirm({
          text: 'Terminare la sessione video?',
          title: 'Attenzione',
        })

        if (res) this.video = false
      }
    },

    onStartRemoteAccess() {
      this.count = true
      const offset = this.session.project.prefix * 10000000000

      const dest =
        offset +
        parseInt(this.session.session_code) * 100000 +
        parseInt(this.session.client_code)

      window.open(
        `/manage/sessions/${this.session.id}/remote/${dest}`,
        this.session.id,
        'width=1600,height=900,status=no,menubar=no,toolbar=no,location=no,scrollbars=no,resizable=no'
      )
    },
  },
}
</script>

<style scoped></style>
