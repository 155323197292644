<template>
  <v-container fluid class="px-lg-10">
    <div v-if="session.type === 'video'">
      <SessionVideo />
    </div>

    <SessionRemoteDesktop v-else />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import SessionVideo from '@components/sessions/SessionVideo.vue'
import SessionRemoteDesktop from '@components/sessions/SessionRemoteDesktop.vue'

export default {
  name: 'SessionDetail',
  components: { SessionRemoteDesktop, SessionVideo },
  data: () => ({
    previusDrawer: false,
    timer: 0,
    timerEnabled: false,
  }),
  computed: {
    ...mapState('sessions', {
      session: 'current',
      pending: 'pending',
    }),
    ...mapState('ui', {
      drawer: 'drawer',
    }),
  },
  // methods
  mounted() {
    this.previusDrawer = this.drawer
    this.setDrawerVisible(false)
    this.setFooterVisibile(false)
  },
  beforeDestroy() {
    this.setDrawerVisible(this.previusDrawer)
    this.setFooterVisibile(true)
  },
  beforeRouteLeave(to, from, next) {
    if (!this.pending) return next()

    this.$dialog
      .confirm({
        text: `Una sessione è in corso. Vuoi veramente uscire?`,
        title: `Attenzione`,
      })
      .then((answer) => {
        if (answer) {
          next()
        } else {
          next(false)
        }
      })
  },

  methods: {
    ...mapActions('sessions', ['setCurrent']),
    ...mapMutations('ui', {
      setDrawerVisible: 'SET_DRAWER',
      setFooterVisibile: 'SET_FOOTER',
    }),
  },
}
</script>
